<script setup lang="ts">
import { type PropType, useSlots } from 'vue'
import Loading from '../icons/others/Loading.vue'

export type TVariant = 'normal' | 'outlined' | 'text'
export type TSize = 'small' | 'normal' | 'medium'

const props = defineProps({
  type: {
    type: String as PropType<'button' | 'submit' | 'reset'>,
    default: 'button'
  },
  size: {
    type: String as PropType<TSize>,
    default: 'normal'
  },
  variant: {
    type: String as PropType<TVariant>,
    default: 'normal'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  text: {
    type: String,
    default: 'Button'
  },
  loading: {
    type: Boolean,
    default: false
  },
  heightOverride: {
    type: String,
    default: ''
  },
  widthOverride: {
    type: String,
    default: ''
  },
  to: {
    type: String,
    default: ''
  },
  mode: {
    type: String as PropType<'clickEmit' | 'navigation' | 'windowOpen'>,
    default: 'clickEmit'
  }
})

const slots = useSlots()

const emit = defineEmits(['click'])

function handleClick() {
  if (props.loading || props.disabled) {
    return
  }
  switch (props.mode) {
    case 'navigation':
      if (props.to) {
        navigateTo(props.to)
      }
      break
    case 'windowOpen':
      if (props.to) {
        window.open(props.to)
      }
      break
    default:
      emit('click')
      break
  }
}
</script>
<template>
  <button
    v-if="props.variant === 'normal'"
    :type="type"
    class="rounded-button px-[1.25rem] text-white transition-colors duration-100 ease-in focus-visible:ring-2 focus-visible:ring-blue-40"
    :aria-label="props.text"
    :class="{
      ' bg-secondary-2 text-primary ': !props.disabled,
      'hover:bg-purple-40 active:shadow-md': !props.loading && !props.disabled,
      'bg-gray-30 text-white': props.disabled,
      'h-[2.375rem] text-t6 font-light': props.size === 'small',
      'h-[2.5rem] text-h5 font-medium': props.size === 'normal',
      'h-[2.75rem] text-h4 font-medium': props.size === 'medium'
    }"
    :disabled="props.disabled || props.loading ? true : false"
    :style="{
      height: props.heightOverride ? props.heightOverride : undefined,
      width: props.widthOverride ? props.widthOverride : undefined
    }"
    @click.stop="handleClick"
  >
    <div class="flex-rows flex items-center justify-center gap-[0.31rem]">
      <div
        v-if="slots['left-icon'] && !(!props.disabled && props.loading)"
        :class="{
          'h-[1.125rem] w-[1.125rem]': props.size === 'small',
          'h-[1.25rem] w-[1.25rem]': props.size === 'normal',
          'h-[1.5rem] w-[1.5rem]': props.size === 'medium'
        }"
      >
        <slot name="left-icon" :disabled="props.disabled"></slot>
      </div>

      <div
        v-if="!props.disabled && props.loading"
        :class="{
          'h-[1.125rem] w-[1.125rem]': props.size === 'small',
          'h-[1.25rem] w-[1.25rem]': props.size === 'normal',
          'h-[1.5rem] w-[1.5rem]': props.size === 'medium'
        }"
      >
        <Loading />
      </div>

      <slot>
        <div v-if="props.text">{{ props.text }}</div>
      </slot>
      <div
        v-if="slots['right-icon'] && !(!props.disabled && props.loading)"
        :disabled="props.disabled"
        :class="{
          'h-[1.125rem] w-[1.125rem]': props.size === 'small',
          'h-[1.25rem] w-[1.25rem]': props.size === 'normal',
          'h-[1.5rem] w-[1.5rem]': props.size === 'medium'
        }"
      >
        <slot
          v-if="!(!props.disabled && props.loading)"
          name="right-icon"
          :disabled="props.disabled"
        ></slot>
      </div>
    </div>
  </button>
</template>
